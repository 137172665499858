var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row align-items-start"},_vm._l((_vm.games.filter(
        function (g) { return (_vm.idteam1 === g.team_id_side_1 && _vm.idteam2 === g.team_id_side_2) ||
          (_vm.idteam1 === g.team_id_side_2 && _vm.idteam2 === g.team_id_side_1); }
      )),function(game,index){return _c('div',{key:index,staticClass:"game-score align-self-start col-6 p-0"},[(
          _vm.idteam1 === game.team_id_side_1 && _vm.idteam2 === game.team_id_side_2
        )?_c('div',{staticClass:"py-1"},[(game.score_side1 > game.score_side2)?_c('span',{staticClass:"score-block"},[(game.side1_id === game.team_overtime_win)?_c('span',{class:_vm.dataSport.textClass},[_vm._v("OT ")]):_vm._e(),_c('span',{class:_vm.dataSport.textClass},[_vm._v(_vm._s(game.score_side1))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side2))])]):(game.score_side1 < game.score_side2)?_c('span',{staticClass:"score-block"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side1))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{class:_vm.dataSport.textClass},[_vm._v(_vm._s(game.score_side2))]),(game.side2_id === game.team_overtime_win)?_c('span',{class:_vm.dataSport.textClass},[_vm._v(" OT")]):_vm._e()]):(
            game.score_side1 === game.score_side2 && game.state === 'finished'
          )?_c('span',{staticClass:"score-block"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side1))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side2))])]):_c('span',[_c('b',{staticClass:"separator text-white-50"},[_vm._v("-")])])]):(
          _vm.idteam1 === game.team_id_side_2 && _vm.idteam2 === game.team_id_side_1
        )?_c('div',{staticClass:"py-1"},[(game.score_side2 > game.score_side1)?_c('span',{staticClass:"score-block"},[(game.side2_id === game.team_overtime_win)?_c('span',{class:_vm.dataSport.textClass},[_vm._v("OT ")]):_vm._e(),_c('span',{class:_vm.dataSport.textClass},[_vm._v(_vm._s(game.score_side2))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side1))])]):(game.score_side2 < game.score_side1)?_c('span',{staticClass:"score-block"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side2))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{class:_vm.dataSport.textClass},[_vm._v(_vm._s(game.score_side1))]),(game.side1_id === game.team_overtime_win)?_c('span',{class:_vm.dataSport.textClass},[_vm._v(" OT")]):_vm._e()]):(
            game.score_side2 === game.score_side1 && game.state === 'finished'
          )?_c('span',{staticClass:"score-block"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side1))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side2))])]):_c('span',[_c('b',{staticClass:"separator text-white-50"},[_vm._v("-")])])]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }