<template>
  <div class="row align-items-start">
    <div
      v-for="(game, index) in games.filter(
        g =>
          (idteam1 === g.team_id_side_1 && idteam2 === g.team_id_side_2) ||
          (idteam1 === g.team_id_side_2 && idteam2 === g.team_id_side_1)
      )"
      :key="index"
      class="game-score align-self-start col-6 p-0"
    >
      <div
        v-if="
          idteam1 === game.team_id_side_1 && idteam2 === game.team_id_side_2
        "
        class="py-1"
      >
        <span v-if="game.score_side1 > game.score_side2" class="score-block">
	        <span v-if="game.side1_id === game.team_overtime_win" :class="dataSport.textClass">OT&nbsp;</span>
          <span :class="dataSport.textClass">{{ game.score_side1 }}</span>
          <span class="separator text-white">-</span>
          <span class="text-white">{{ game.score_side2 }}</span>
        </span>
        <span
          v-else-if="game.score_side1 < game.score_side2"
          class="score-block"
        >
          <span class="text-white">{{ game.score_side1 }}</span>
          <span class="separator text-white">-</span>
          <span :class="dataSport.textClass">{{ game.score_side2 }}</span>
	        <span v-if="game.side2_id === game.team_overtime_win" :class="dataSport.textClass">&nbsp;OT</span>
        </span>
        <span
          v-else-if="
            game.score_side1 === game.score_side2 && game.state === 'finished'
          "
          class="score-block"
        >
          <span class="text-white">{{ game.score_side1 }}</span>
          <span class="separator text-white">-</span>
          <span class="text-white">{{ game.score_side2 }}</span>
        </span>
        <span v-else>
          <b class="separator text-white-50">-</b>
        </span>
      </div>
      <div
        v-else-if="
          idteam1 === game.team_id_side_2 && idteam2 === game.team_id_side_1
        "
        class="py-1"
      >
        <span v-if="game.score_side2 > game.score_side1" class="score-block">
	        <span v-if="game.side2_id === game.team_overtime_win" :class="dataSport.textClass">OT&nbsp;</span>
          <span :class="dataSport.textClass">{{ game.score_side2 }}</span>
          <span class="separator text-white">-</span>
          <span class="text-white">{{ game.score_side1 }}</span>
        </span>
        <span
          v-else-if="game.score_side2 < game.score_side1"
          class="score-block"
        >
          <span class="text-white">{{ game.score_side2 }}</span>
          <span class="separator text-white">-</span>
          <span :class="dataSport.textClass">{{ game.score_side1 }}</span>
	        <span v-if="game.side1_id === game.team_overtime_win" :class="dataSport.textClass">&nbsp;OT</span>
        </span>
        <span
          v-else-if="
            game.score_side2 === game.score_side1 && game.state === 'finished'
          "
          class="score-block"
        >
          <span class="text-white">{{ game.score_side1 }}</span>
          <span class="separator text-white">-</span>
          <span class="text-white">{{ game.score_side2 }}</span>
        </span>
        <span v-else>
          <b class="separator text-white-50">-</b>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GridGamesScore",
  props: ["games", "idteam1", "idteam2", "dataSport"]
};
</script>

<style scoped>
.separator {
  font-size: 17px;
  font-weight: 400;
  padding: 0 2px;
}
.game-score {
  font-size: 14px;
  line-height: 17px;
}
.score-block::after {
  content: " ";
  display: block;
}
</style>
